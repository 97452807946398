@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hover-ring {
    @apply ring-offset-2 ring-offset-gray-900 transition duration-300 hover:outline-none hover:ring-2 hover:ring-white;
  }
  .focus-ring {
    @apply ring-offset-2 ring-offset-gray-900 transition duration-300 focus:outline-none focus:ring-2 focus:ring-white;
  }
  .active-ring {
    @apply outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-900 transition duration-300;
  }
  .hover-highlight {
    @apply text-gray-300 hover:text-gray-100 hover:opacity-100;
  }
  .description .match {
    @apply bg-yellow-200 text-gray-900;
  }
  .bookmarks-container div {
    @apply justify-center;
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-600
  }
  
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700
  }
}
