/* Scrollbar */

.no-scroll::-webkit-scrollbar {
  display: none;
  height: 10px !important;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


::-webkit-scrollbar-track {
  background: transparent;
  width: 1px !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: gray; */
  width: 1px !important;
  border-radius: 10px;
}

/* ::-webkit-scrollbar-thumb:hover {
  background: gray;
} */

/* SPINNER */

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.spinner div {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 5px solid #85a2b6;
  border-top-color: transparent;
  border-radius: 50%;
}
.spinner div {
  animation: spinner 1s linear infinite;
  top: 50%;
  left: 50%;
}
.spinner-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.7;
  z-index: 1;
}
.spinner {
  width: 100%;
  height: 100%;
  background: #f1f2f3;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinner div {
  box-sizing: content-box;
}

/* Button spinner */

.spinner-button {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  display: inline-block;
  vertical-align: text-bottom;
  margin-bottom: 2px;
  margin-right: 3px;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-button 0.75s linear infinite;
  animation: spinner-button 0.75s linear infinite;
}

@keyframes spinner-button {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bookmarks-container.col-3 > div {
  display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 10px;
}
.bookmarks-container.col-4 > div {
  display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 10px;
}
.bookmarks-container.col-5 > div {
  display: grid !important;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 10px;
}
.bookmarks-container.col-6 > div {
  display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 10px;
}
.bookmarks-container.col-7 > div {
  display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 10px;
}
.bookmarks-container.col-8 > div {
  display: grid !important;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 16px;
    margin-bottom: 16px;
}

.react-grid-item.react-grid-placeholder {
  background-color: #1E3A8A !important;
  opacity: 0.2 !important;
}

.react-resizable-handle {
  filter: invert(1);
}

/* react-date-range */

.rdrDayToday .rdrDayNumber span:after {
  display: none;
}

.rdrDefinedRangesWrapper {
  border-radius: 10px 0px 0px 0px;
}

.rdrStaticRanges * {
  border-top-left-radius: 10px;
}

.rdrCalendarWrapper {
  border-radius: 0px 10px 0px 0px;
}

.react-grid-item:hover{
  z-index: 10;
  }

/* AG Grid */

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 5px;
}

.ag-theme-alpine {
  --ag-foreground-color: white;
  --ag-background-color: #1f2937;
  --ag-header-background-color: #111827;
  --ag-odd-row-background-color: #1f2937;
  --ag-border-color: #000;
  --ag-secondary-border-color: transparent;
}

.ag-cell-focus {
  border-color: transparent !important;
}

.ag-cell-value {
  cursor: text;
}

.grid-btn .ag-cell-value {
  overflow: visible;
}

.grid-btn .ag-cell-wrapper {
  display: block;
}

/* .ag-header-cell-text {
  color: lightgray
} */

.h-full .ag-cell-wrapper {
  height: 100%;
}